import React from 'react'
import axios from 'axios'
import moment from 'moment'
import ReactTooltip from 'react-tooltip';
import { browserName, deviceType, osName } from "react-device-detect";
import _ from 'lodash'

import * as Constants from './constants'
import { Notify } from '../components/notifications'
import { Redirect } from '../components/notifications/redirect'
import { versionupdate } from '../components/modals/version_update_modal'

import { ModalAlert } from '../components/notifications/alert_modal'

import numeral from "numeral";
import CountTo from "react-count-to";

export const Header = {
  'Content-Type': 'application/json'
}
export const BaseUrl = APP_API_URL

export const getUserIdFromLocalStorage = () => {
  let user = JSON.parse(localStorage.getItem(APP_NAME))
  if (user && user[Constants.CO_USER].id) {
    return user[Constants.CO_USER].id
  } else {
    console.warn('No token found')
    return null
  }
}

export const getAccessToken = () => {
  let user = JSON.parse(localStorage.getItem(APP_NAME))
  if (user && user[Constants.CO_USER].jwtToken) {
    return user[Constants.CO_USER].jwtToken
  } else {
    console.warn('No token found')
    return null
  }
}

export const clearLocalStorage = () => {
  localStorage.removeItem(APP_NAME)
}

export const getAuthHeader = () => {
  let header = Header
  if (getAccessToken()) {
    header = Object.assign({}, Header, {
      Authorization: 'Bearer: ' + getAccessToken(),
      'X-Co-App-Version': APP_VERSION
    })
  } else {
    header = Object.assign({}, Header, {
      'X-Co-App-Version': APP_VERSION
    })
  }
  return header
}

export const getMultipartAuthHeader = () => {
  let header
  if (getAccessToken()) {
    header = Object.assign({}, {
      Authorization: 'Bearer: ' + getAccessToken(),
      'X-Co-App-Version': APP_VERSION
    })
  }
  return header
}

export function ajax(url, method, data = null, appendBaseUrl = true, cancelToken = null, appendAuth = true, withCredentials = false) {
  return new Promise((resolve, reject) => {
    let apiUrl = url
    if (appendBaseUrl)
      apiUrl = BaseUrl + url

    const headers = getAuthHeader()

    var options = {}
    if (appendAuth) {
      options = {
        url: apiUrl,
        method: method,
        headers
      };
    } else {
      options = {
        url: apiUrl,
        method: method,
        header: Object.assign({}, {
          'Content-Type': 'application/json'
        }, {
          'X-Cop-App-Version': APP_VERSION
        })
      }
    }

    if (data) {
      options.data = data
    }
    if (cancelToken) {
      options.cancelToken = cancelToken
    }
    if (withCredentials) {
      options.withCredentials = true
    }
    axios(options).then((res) => {
      resolve(res.data)
    }).catch((err) => {
      let notificationDetails
      if (err.response) {
        switch (err.response.status) {
          case 500:
            //Internal Server Error'
            break

          case 401:
            console.error('Unauthorized')
            ModalAlert().show({
              title: 'Login Required',
              content: "Session expired. Please login again to continue.",
              onCloseHandler: window.clearout.signOutClick,
              onHideHandler: window.clearout.signOutClick
            })
            break

          case 402:
            notificationDetails = {
              title: 'Payment Required',
              message: err.response.data.error && err.response.data.error.additional_info ? ('You have exhausted your credits, please add additional ' + err.response.data.error.additional_info.required_credits + ' credits to continue.') : err.response.data.error.message,
              level: "error",
              uid: 'payment-required-error'
            }
            Notify().showNotification(notificationDetails)
            setTimeout(() => {
              Redirect().redirect('/account/pricing')
            }, 5000)
            break

          case 429:
            console.log('error', err)
            break;

          case 400:
            //Bad request
            break
          case 418:
            versionupdate().openModal(err.response.data)
            break
          default:
            break
        }
      }

      reject(err)
    })
  })
}

export function validateEmailAddress(email) {
  let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email)
}

export function validatePhoneNumber(number) {
  let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(number)
}

export function isNumber(value) {
  let regex = /^[0-9]+$/;
  return regex.test(value)
}

export function isAlphabet(value) {
  let regex = /^[a-zA-Z\s]*$/
  return regex.test(value)
}

export function capitalizeFirstLetter(string) {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return string
}

export function getMetrics(value, precision, prefix, suffix, heading, isMetricsLoading) {
  if (isMetricsLoading) {
    return (
      <span data-tip="Loading...">
        -
      </span>
    );
  }
  let counter =
    value > 999
      ? numeral(value)
        .format('0.00a')
        .slice(0, -1)
      : value;
  let abbr =
    value > 999
      ? numeral(value)
        .format('0.00a')
        .slice(-1)
      : '';
  let decimalPrecision = precision;
  if (value < 999 && heading !== 'Found Percentage' && heading !== 'Bounce Detected') {
    decimalPrecision = 0;
  }

  let tooltipText = Number(value).toLocaleString(Constants.CURRENCY.USD.locale);

  if (heading === 'Email Found' || heading === 'Total Verified') {
    tooltipText = Number(value);
  }
  if (suffix)
    tooltipText = tooltipText + ' ' + suffix
  if (prefix)
    tooltipText = prefix + ' ' + tooltipText

  return (
    <>
    <span data-tip={tooltipText} data-for={`${heading}`}>
      {prefix}
      <CountTo to={Number(counter)} speed={1500} digits={decimalPrecision} />
      {capitalizeFirstLetter(abbr)}
      {suffix}
    </span>
    <ReactTooltip className='custom-header-tooltip' id={`${heading}`} place='bottom' />
  </>  
  );
}


export function calculateBounceRate(stats) {
  let bounceRate = 0
  let count = stats.total - stats.unknown
  if (stats.total !== 0) {
    bounceRate = (Number(stats.invalid) / Number(count) * 100)
  }
  return bounceRate > 0 ? bounceRate : 0
}

export function calculateEstimatedCostSaved(count) {
  let costSaved = 0
  costSaved = (Number(count) * Constants.AVG_EMAIL_SENDING_COST)
  return costSaved
}

export function getDeliveryStatusFromDeliverableScore(deliverable_score) {
  let status = {}
  if (deliverable_score === 100) {
    status.name = 'No Risk'
    status.value = 'no_risk'
  } else if (deliverable_score >= 75) {
    status.name = 'Low Risk'
    status.value = 'no_risk'
  } else if (deliverable_score >= 50) {
    status.name = 'Moderate Risk'
    status.value = 'moderate_risk'
  } else {
    status.name = 'High Risk'
    status.value = 'high_risk'
  }
  return status
}

export function pickChartColor(index) {
  if (index > Constants.GRAPH_COLORS.length - 1) {
    index = index % (Constants.GRAPH_COLORS.length - 1)
  }
  let color = Constants.GRAPH_COLORS[index]
  return color
}

export function pickAreaChartColor(index) {
  if (index > Constants.GRAPH_AREA_COLORS.length - 1) {
    index = index % (Constants.GRAPH_AREA_COLORS.length - 1)
  }
  let color = Constants.GRAPH_AREA_COLORS[index]
  return color
}

export function formatTime(time, format) {
  return moment.parseZone(time).format(format)
}

export function calculatePrice(credits) {
  let slabs,
    slab = {
      max: 0,
      min: 0,
      unit_price: 0
    },
    actual_amount = 0
  let user = JSON.parse(localStorage.getItem(APP_NAME))
  if (user && user['appReducer'].slabs) {
    slabs = user['appReducer'].slabs
  }
  if (slabs && slabs.length > 0) {
    slab = slabs && slabs.find(function (s) {
      if (s.min <= credits && (s.max ? s.max : Infinity) >= credits)
        return s
    })
    actual_amount = credits * slab.unit_price
  }
  return {
    slab: slab,
    credits: credits,
    amount: actual_amount
  }
}

export function calculateTax(amount, currency) {
  let taxAmount = { tax: 0, total: amount }
  switch (currency) {
    case Constants.CURRENCY.INR.name: {
      taxAmount.tax = amount * 0.18
      taxAmount.total = formatNumberToXDecimalPlace(amount, 2) + formatNumberToXDecimalPlace(taxAmount.tax, 2)
    }
      break
    default:
      break
  }

  return taxAmount
}

export function formatNumberToXDecimalPlace(number, x) {
  const DOT = '.'
  number = Math.round(number * 1e10) / 1e10
  number = number.toString()
  let indexOfDot = number.indexOf(DOT)

  if (indexOfDot !== -1) {
    number = number.slice(0, indexOfDot + x + 1)
  }

  return +number
}

export function getResultAsReqFormat(result, metric) {
  if (Constants.RESULTS[result].type == 'percentage') {
    if (metric && metric.value !== null) {
      let val = Number(metric.value).toLocaleString(Constants.CURRENCY.USD.locale)
      let percent = metric.value ? ' (' + Number(metric.percentage) + '%)' : ''
      return val + percent
    } else {
      return '-'
    }
  } else {
    return isNumber(metric) ? Number(metric).toLocaleString(Constants.CURRENCY.USD.locale) : '-'
  }
}



export function addHttp(url) {
  if (!/^(f|ht)tps?:\/\//i.test(url)) {
    url = "http://" + url;
  }
  return url;
}

export function popupCenter(url, title, w, h, callback, nextAction) {
  var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : screen.left;
  var dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top;

  let width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
  let height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

  var left = ((width / 2) - (w / 2)) + dualScreenLeft;
  var top = ((height / 2) - (h / 2)) + dualScreenTop;
  var newWindow = window.open(url, title, 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);
  var timer = setInterval(checkChild, 500);

  function checkChild() {
    if (newWindow.closed) {
      // Make api call here... on window closed
      if (callback)
        callback(nextAction)

      clearInterval(timer);
    }
  }
  // Puts focus on the newWindow
  if (window.focus) {
    newWindow.focus();
  }
}

export function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function validateUserName(username) {
  let regex = /^[a-zA-Z0-9._\s]*$/;
  return regex.test(username)
}


export function getHumanizedTime(value) {
  let diffInMins = moment.duration(value).asMinutes()
  diffInMins = Math.round(diffInMins)
  let time = diffInMins > 1 ? ' minutes ' : ' minute '
  let readableTime = diffInMins + time + ' '
  if (diffInMins > 1) {
    if (diffInMins > 60 && diffInMins < 1440) {
      let hours = moment.duration(value).hours()
      let minutes = Math.round(diffInMins - hours * 60)
      time = hours > 1 ? ' hours ' : ' hour '
      readableTime = hours + time + minutes + ' minutes '
    }
    else if (diffInMins > 1440) {
      let days = Math.floor(moment.duration(value).asDays())
      let hour = Math.floor((diffInMins - (days * 24 * 60)) / 60)
      let minute = Math.round(diffInMins - (days * 24 * 60) - (hour * 60))
      time = days > 1 ? ' days ' : ' day '
      readableTime = days + time + hour + ' hours ' + minute + ' minutes'
    }
    return readableTime
  } else {
    return moment.duration(value).humanize()
  }
}

export function getRandomId() {
  return Math.floor(Math.random() * 90000) + 10000;
}

export function showPopupBlockerErrorModal() {
  let browser_name = browserName.toLowerCase()
  let device_type = deviceType.toLowerCase()
  let os_name = osName.toLowerCase()

  // check if all three conditions satisfy
  let deviceObj = _.find(Constants.POPUP_WINDOW_BROWSER_ENABLE_LINKS, function (device) {
    if (device.browser.includes(browser_name) && device.os.includes(os_name) && device.type.includes(device_type)) {
      return device
    }
  })

  // check if at least type and os cond satisfy
  if (!deviceObj) {
    deviceObj = _.find(Constants.POPUP_WINDOW_BROWSER_ENABLE_LINKS, function (device) {
      if (device.type.includes(device_type) && device.os.includes(os_name)) {
        return device
      }
    })
  }

  // if all conditions failed, select chrome as default
  if (!deviceObj) {
    deviceObj = Constants.POPUP_WINDOW_BROWSER_ENABLE_LINKS[0]
  }

  let url = new URL(window.location.href)

  ModalAlert().show({
    title: "Download Blocked",
    content: <span>Your browser is preventing popups, please allow popup blocker for <strong>{url.hostname}</strong> domain,
      <a href={deviceObj.link} target="blank"> Click Here</a> to know more</span>
  })
}

export function getErrorStatusColor(status) {
  switch (status) {
    case "success":
      return "success-message"

    case "failed":
    case "error":
      return "error-message"

    default:
      return ""
  }
}

export const RenderIf = ({ children, isTrue }) => {
  return isTrue ? children : null
}

export function convertEmailsCommaSeparatorStringToArray(emails) {
  if (!_.isEmpty(emails)) {
    emails = emails != '' ? [...new Set(emails.split(','))] : '' //Removing all duplicates
    emails = emails.map(item => item.trim()) // trim the array values
    //remove empty values from array
    emails = emails.filter(function (el) {
      return el != ''
    })
    emails = emails.slice(0, 10) //Taking only first 10 emails
  }
  return emails
}

export function calculateConfidenceLevelTagFromScore(confidence_score) {
  let confidenceLevel
  // console.log(confidence_score)
  if (confidence_score) {
    if (confidence_score > 0 && confidence_score <= 30) confidenceLevel = 'Very Low'
    else if (confidence_score > 30 && confidence_score <= 60) confidenceLevel = 'Low'
    // else if (confidence_level > 60 && confidence_level <= 60) confidence_text = "Medium"
    else if (confidence_score > 60 && confidence_score <= 80) confidenceLevel = 'Moderate'
    else if (confidence_score > 80) confidenceLevel = 'High'
    else confidenceLevel = '-'
  } else { // For backward compatibility sakes
    confidenceLevel = '-'
  }

  return confidenceLevel
}

export function getColorTagFromConfidenceLevel(confidence_level) {
  let color

  switch (confidence_level) {
    case 'Very Low':
      color = 'red'
      break
    case 'Low':
      color = 'coral'
      break
    case 'Moderate':
      color = 'blue'
      break
    case 'High':
      color = 'green'
      break
    default:
      color = 'orange'
  }
  return color
}

export function truncateMiddleString(inputString, maxLength) {
  // Check if inputString is undefined, null, or empty
  if (!inputString || inputString.length === 0) {
    return ''; // Return an empty string if inputString is falsy or empty
  }

  // Check if inputString length is greater than maxLength
  if (inputString.length > maxLength) {
    // Calculate the length of the truncated start and end parts
    const startLength = Math.floor((maxLength - 3) / 2); // Subtracting 3 for the ellipsis
    const endLength = Math.ceil((maxLength - 3) / 2);

    // Truncate the middle part of the string
    const truncatedString = inputString.slice(0, startLength) + '...' + inputString.slice(-endLength);
    return truncatedString;
  } else {
    return inputString; // Return the original string if it's already within the maxLength
  }
}

export function formatUSDNumber(num) {
  if (num === 0) {
    return 'Undisclosed Amount';
  }

  const format = (number, divisor, suffix) => {
    const result = (number / divisor);
    // Truncate to one decimal place without rounding
    const truncatedResult = Math.ceil(result * 10) / 10;
    return truncatedResult % 1 === 0 ? `$${parseInt(truncatedResult)}${suffix}` : `$${truncatedResult}${suffix}`;
  };

  if (num >= 1e9) {
    return format(num, 1e9, 'B');
  } else if (num >= 1e6) {
    return format(num, 1e6, 'M');
  } else if (num >= 1e3) {
    return format(num, 1e3, 'K');
  }
  return `$${num}`;
}